import React, {useState} from 'react'
import axios from 'axios'
import {Title, Subtitle, Image, Lead, Text, List, Blockquote, Video} from './AritcleAdminComponents'

// import './editor'

const availableComponents = {
   title: Title,
   subtitle: Subtitle,
   image: Image,
   text: Text,
   blockquote: Blockquote,
   // lead: Lead,
   // video: Video
};

const hrComponents = {
   title: "Naslov",
   subtitle: "Podnaslov",
   image: "Slike",
   text: "Tekst",
   blockquote: "Istaknuto",
   // lead: "Uvod",
   // video: "Video"
};

const Editor = props => {

  const [elems, addElems] = useState(props.components)
  const [deleted, deleteElem] = useState([])

  const forceUpdate = (articleId) => {
    addElems([])
    axios.get('/admin/components/' + props.model_name + '/' + articleId)
    .then(function (response) {
      addElems(response.data);
    }).catch(function (error) { console.log(error); })
  }

  const showInput = (elem, e) => {
    e.preventDefault()
    const new_elem = {
      name: elem,
      props: {content: ""}
    }
    addElems([...elems, new_elem])
  }

  const removeInput = (id, index, e) => {
    e.preventDefault()
    axios.get('/admin/delete_component/' + id)
    .then(function (response) {
      deleteElem([...deleted, index])
    }).catch(function (error) { console.log(error); })
  }


  return <div className="sd_editor">
    <div className="left">
      {elems.map((elem, index) =>{
          if(availableComponents[elem.name]) {
            const TagName = availableComponents[elem.name]
            if(!deleted.includes(index)){
              return <TagName
                        key={index}
                        index={index}
                        id={elem.id}
                        src={elem.src}
                        removeInput={removeInput}
                        useForceUpdate={forceUpdate}
                        model_name={props.model_name}
                        {...elem.props} />
            }
          }
        }
      )}
    </div>
    <div className="buttons">
      {Object.keys(availableComponents).map((component, index) =>
        <button key={index} onClick={(e) => showInput(component, e)}>{hrComponents[component]}</button>
      )}
    </div>
  </div>
}

export default Editor
