import React from 'react'
import {Title, Subtitle, Image, Lead, Text, List, Blockquote, Video} from './AritcleComponents'

const availableComponents = {
   title: Title,
   subtitle: Subtitle,
   image: Image,
   lead: Lead,
   text: Text,
   list: List,
   blockquote: Blockquote,
   video: Video
};

const Article = (props) => {
  return <article>
    {props.components.map((component, index) =>{
        if(availableComponents[component.name]) {
          const TagName = availableComponents[component.name]
          return <TagName key={index} index={index} {...component.props} src={component.src ? component.src : null} />
        }
      }
    )}
  </article>
}

export default Article

// <Title content="Naslov" />
// <Image src="https://via.placeholder.com/300x300" />
// <Lead content="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />
// <Subtitle content="Podnaslov" />
// <Text content={[lorem, lorem]} />
// <Blockquote content={lorem} />
// <Text content={[lorem, lorem]} />
// <img src="https://via.placeholder.com/200x200" />
// <Text content={[lorem, lorem]} />
// <List items={["jedan", "dva", "tri"]} />
// <Text content={[lorem, lorem]} />
// <List ordered items={["jedan", "dva", "tri"]} />
// <Text content={[lorem, lorem]} />
// <Video width="560" height="315" src="https://www.youtube.com/embed/ZE_TrbJ6X-A" />
