import React from 'react'
import Fade from 'react-reveal/Fade';

export const Title = props => (
  <h2>{props.content}</h2>
)

export const Subtitle = props => (
  <h3>{props.content}</h3>
)

export const Image = props => {
  // <Fade left={(props.index !== 0)} right={(props.index === 0)} big delay>
  const images = props.src.map((img, index) =>
    <a key={index} href={img.url} data-lightbox={"gallery_" + props.index}>
      {index < 1 &&
      <img src={img.small.url} className={props.main ? 'main' : null} />
      }
    </a>
  )
  return <Fade left big delay={300 * props.index}>
    <div className={images.length > 1 ? 'has_gallery' : ''}>{images}</div>
  </Fade>
}

export const Lead = props => (
  <div className="lead">{props.content}</div>
)

export const Text = props => (
  <div dangerouslySetInnerHTML={{
    __html: props.content
  }}></div>
)

export const List = (props) => {
  const items = props.items.map((item, index) =>
    <li key={index}>{item}</li>
  )
  return props.ordered ? <ol>{items}</ol> : <ul>{items}</ul>
}

export const Blockquote = props => (
  <blockquote>{props.content}</blockquote>
)

export const Video = props => (
  <iframe width={props.width} height={props.height} src={props.src} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
)
