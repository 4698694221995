// app/javascript/components/QuillEditor.jsx

import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const QuillEditor = (props) => {
  const [content, setContent] = useState(props.content);

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    ['link'],
  
    [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
  
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
  
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],
  
    ['clean']                                         // remove formatting button
  ];

  const modules = {
    toolbar: toolbarOptions,
    clipboard: {
      matchVisual: false,
    }
  };

  useEffect(() => {
    setContent(props.content);
  }, [props.content]);

  const handleChange = (content, delta, source, editor) => {
    setContent(editor.getHTML());
  };

  return (
    <div class="pb-10" style={{ width: '79%', marginLeft: '20%', marginBottom:'3%'}}>
      <input type="hidden" name={props.name} value={content} />
      <ReactQuill
        value={content}
        onChange={handleChange}
        modules={modules}
        formats={[
            'bold', 'italic', 'underline', 'strike',
            'blockquote', 'code-block',
            'link',
            'list', 'bullet', 'check',
            'indent',
            'size',
            'color', 'background',
            'font',
            'align'
        ]}
        style={{ height: props.height }}
      />
    </div>
  );
};

export default QuillEditor;
