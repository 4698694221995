/* eslint no-console:0 */
// const css = require.context("../../assets/stylesheets")
// css.keys().forEach(css)

import 'bootstrap';

require("../../assets/stylesheets/bootstrap");
require("../../assets/stylesheets/main.css");
require("../../assets/stylesheets/vex");
require("../../assets/stylesheets/vex-theme-plain");
require("../../assets/stylesheets/sd");
require("../../assets/stylesheets/lightbox");
const js = require.context("../../assets/javascripts");
js.keys().forEach(js);

const componentRequireContext = require.context("components", true);
const ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

// Ensure QuillEditor is correctly imported and used if necessary
//import React from 'react';
//import { render } from 'react-dom';

document.addEventListener('DOMContentLoaded', () => {
  const reactElements = document.querySelectorAll('[data-react-class]');

  reactElements.forEach((element) => {
    const reactClass = element.getAttribute('data-react-class');
    const reactProps = JSON.parse(element.getAttribute('data-react-props'));

    if (reactClass === 'QuillEditor') {
      render(<QuillEditor {...reactProps} />, element);
    }
  });
});
