import React from 'react'
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

class Gallery extends React.Component {
  constructor() {
    super();

    this.state = {
      showIndex: false,
      infinite: true,
      showThumbnails: true,
      showFullscreenButton: false,
      showPlayButton: false,
      showNav: true,
      isRTL: false,
      slideDuration: 450,
      slideInterval: 2000,
      slideOnThumbnailOver: false,
      thumbnailPosition: 'top',
      useWindowKeyDown: true,
    };
  }

  render() {
    return <ImageGallery
      items={this.props.images}
      infinite={this.state.infinite}
      showFullscreenButton={this.state.showFullscreenButton}
      showPlayButton={this.state.showPlayButton}
      showThumbnails={this.state.showThumbnails}
      showIndex={this.state.showIndex}
      showNav={this.state.showNav}
      isRTL={this.state.isRTL}
      thumbnailPosition={this.state.thumbnailPosition}
      slideDuration={parseInt(this.state.slideDuration)}
      slideInterval={parseInt(this.state.slideInterval)}
      slideOnThumbnailOver={this.state.slideOnThumbnailOver}
      additionalClass="app-image-gallery"
      useWindowKeyDown={this.state.useWindowKeyDown}
    />
  }
}

export default Gallery
