require('lightbox2')
require('jquery-ui-bundle')

$(document).ready(function () {

  $('.image img').onSwipe(function (results) {
    if (results.right == true) {
      if ($('#next_link').attr('href'))
        window.location = $('#next_link').attr('href')
    }
    if (results.left == true)
      if ($('#previous_link').attr('href'))
        window.location = $('#previous_link').attr('href')
  });

  if ($('.results li').length == 1) {
    $('.tags-list li').not('.selected').addClass('disabled');
  }

  $('.more-info-button').on('click', function (e) {
    e.preventDefault();
    $('.more-info-container').fadeToggle();
  });

  $('#show-mobile-menu').on('click', function (e) {
    e.preventDefault();
    $('#mobile-menu').animate({ 'left': '0' }, 400);
  });

  $('.simple_form input:radio').on('change', function (e) {
    if (this.value == 'Delivery / Cartage Service') {
      $('.qoute_delivery_address').show();
    } else {
      $('.qoute_delivery_address').hide();
    }
  });

  let searchParams = new URLSearchParams(window.location.search)
  let tags = []

  if (searchParams.get('tag')) {
    tags = searchParams.get('tag').split(",")
  }

  $('.tags-list a').on('click', function (e) {
    e.preventDefault();

    if (!$(this).parent().hasClass('disabled')) {
      $(this).parent().toggleClass('selected');
      let tag = $(this).attr('data-tag')

      if (tags.indexOf(tag) != -1) {
        tags.splice(tags.indexOf(tag), 1);
      } else {
        tags.push(tag);
      }

      if (tags.length == 0) {
        window.location = '/instruments/tags';
      } else {
        window.location = '/instruments?tag=' + tags.join(",");
      }
    }

  });

  $(document).on("input", "#query", function () {
    $(this).autocomplete({
      source: "/autocomplete",
      minLength: 2,
      select: function (event, ui) {
        window.location = '/instruments/' + ui.item.key;
      }
    }).autocomplete("instance")._renderItem = function (ul, item) {
      data = item.maker ? item.value + "<span class='secondary'> - " + item.maker + "</span>" : item.value
      return $("<li>")
        .append(data)
        .appendTo(ul);
    };
  });

});

//Infinite Scroll
let stop = false
let page = 1
$(window).on("scroll", function () {
  const PRODUCTS_LOADER = document.querySelector("#loader-wrapper")

  let scrollHeight = $(document).height();
  let scrollPos = $(window).height() + $(window).scrollTop();

  if (((scrollHeight - 10) >= scrollPos) / scrollHeight == 0) {

    if (stop == false) {
      PRODUCTS_LOADER.removeAttribute("hidden")

      page++;

      page_param = location.href.indexOf('?') == -1 ? "/?page=" : "&page="

      $.get(location.href + page_param + page, function (data) {
        $(".results").append(data);
        if (data == "") {
          stop = true
        }
        PRODUCTS_LOADER.setAttribute("hidden", true)
      });
    }

  }

});
